"use strict";
import packagejson from "../../package.json";

(function(global){

  var app = global.APP = global.APP ? global.APP : {};

  app.version = packagejson.version;

})(window);
